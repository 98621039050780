import Vue from "vue";
const globalRules = {
  test: a => a,
  required: text => [value => !!value || text],
  counter: (text, { min, max, exact }) => [
    value => {
      if (exact) return value.length === exact || text;
      if (min && max) {
        return (value.length > min && value.length < max) || text;
      } else if (min) {
        return value.length > min || text;
      }
      return value.length < max || text;
    }
  ],
  email: text => [
    value => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || text;
    }
  ],
  justNumber: text => [value => new RegExp("^(|-)\\d*$").test(value) || text],
  justNumberWithFloat: text => [
    value => new RegExp("^[+-]?([0-9]*[.])?[0-9]+$").test(value) || text
  ],
  numberRange: (text, { min = -Infinity, max = Infinity }) => [
    value => (value < max && value > min) || text
  ],
  mobile: text => [value => new RegExp("^(09)\\d{9}$").test(value) || text],
  nationalCode: text => [
    value =>
      (/^\d{10}$/.test(value) &&
        !value
          .split("")
          .reduce(
            (acc, number, index) =>
              index < 9
                ? acc + (10 - index) * number
                : acc % 11 < 2
                ? (acc % 11) - number
                : 11 - (acc % 11) - number,
            0
          )) ||
      text
  ]
};

globalRules.install = function() {
  Object.defineProperty(Vue.prototype, "$globalRules", {
    get() {
      return globalRules;
    }
  });
};

Vue.use(globalRules);

export default globalRules;
