import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { encode, decode } from "js-base64";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    redirect: { name: "calendar" },
    component: () => import("@/views/dashboard"),
    children: [
      {
        path: "users",
        name: "users",
        meta: {
          roles: ["admin"],
          icon: "mdi-account-multiple"
        },
        component: () => import("@/views/dashboard/Users")
      },
      {
        path: "actors",
        name: "actors",
        meta: {
          roles: ["admin"],
          icon: "mdi-guy-fawkes-mask"
        },
        component: () => import("@/views/dashboard/Actors")
      },
      {
        path: "scopes",
        name: "scopes",
        meta: {
          roles: ["admin"],
          icon: "mdi-select-group"
        },
        component: () => import("@/views/dashboard/Scopes")
      },
      {
        path: "eventGroups",
        name: "eventGroups",
        meta: {
          roles: ["admin"],
          icon: "mdi-calendar-multiple"
        },
        component: () => import("@/views/dashboard/EventGroups")
      },
      {
        path: "eventSources",
        name: "eventSources",
        meta: {
          roles: ["admin"],
          icon: "mdi-calendar-range"
        },
        component: () => import("@/views/dashboard/EventSources")
      },
      {
        path: "events",
        name: "events",
        meta: {
          roles: ["admin"],
          icon: "mdi-calendar-search",
          disabledInNav: true
        },
        beforeEnter: (to, from, next) => {
          return to.query.eventSource ? next() : next({ name: "eventSources" });
        },
        component: () => import("@/views/dashboard/Events")
      },
      {
        path: "unApproved-events",
        name: "unApproved-events",
        meta: {
          roles: ["admin", "operator"],
          icon: "mdi-calendar-question"
        },
        component: () => import("@/views/dashboard/UnApproved")
      },
      {
        path: "calendar",
        name: "calendar",
        meta: {
          roles: ["admin", "operator", "client"],
          icon: "mdi-calendar"
        },
        component: () => import("@/views/dashboard/Calendar")
      },
      {
        path: "reports",
        name: "reports",
        meta: {
          roles: ["admin", "operator", "client"],
          icon: "mdi-calendar-search"
        },
        component: () => import("@/views/dashboard/Reports")
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login")
  },
  {
    path: "*",
    redirect: { name: "dashboard" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  stringifyQuery(query) {
    return query && Reflect.ownKeys(query).filter(a => a !== "__ob__").length
      ? `?q=${encode(JSON.stringify(query))}`
      : "";
  },
  parseQuery(query) {
    try {
      return query && JSON.parse(decode(query.substring(2)));
    } catch (e) {
      return {};
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.roles) {
    const token = localStorage.getItem("refresh_token");
    if (!token) {
      store.commit("auth/setReferer", to);
      return next({ name: "login" });
    } else {
      return accessController(to, from, next);
    }
  } else {
    return next();
  }
});

/**
 *
 * @param {Object} to - next page
 * @param {Object} to.meta - meta object of next page
 * @param {string[]} to.meta.roles - list of allowed roles in next page
 * @param {Object} from - current page
 * @param {Object} from.meta - meta object of current page
 * @param {string[]} from.meta.roles - list of allowed roles in current page
 * @callback next - next callback function
 * @param {Object} user - current user
 * @param {string} user.role - current user's role
 */
function accessController(to, from, next, user) {
  const role = store.getters["auth/getUserRole"] || (user && user.role);
  if (to.meta.roles && role) {
    return to.meta.roles.includes(role) ? next() : next({ name: "dashboard" });
  } else if (!to.meta.roles) {
    return next();
  } else {
    store
      .dispatch("auth/loginWithToken")
      .then(({ user }) => accessController(to, from, next, user));
  }
}

export default router;
