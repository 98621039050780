import fa from "vuetify/es5/locale/fa";

export default {
  ...fa,
  appTitle: "سامانه مدیریت رویداد",
  ok: "تایید",
  edit: "ویرایش",
  delete: "حذف",
  deleteAll: "حذف همه",
  cancel: "بی خیال",
  apply: "اعمال کردن",
  save: "ذخیره",
  undo: "بازگردانی",
  remove: "حذف کردن",
  logout: "خروج",
  removeMessage: "آیا از حذف این آیتم اطمینان دارید؟",
  filters: "فیلترها",
  uploadXLSX: "ورود اطلاعات از فایل اکسل",
  selectFile: "انتخاب فایل",
  upload: "بارگزاری",
  date: {
    from: "از",
    to: "تا"
  },
  scope: {
    monthly: "ماهانه",
    seasonal: "فصلی",
    annual: "سالانه"
  },
  routes: {
    users: "کاربران",
    actors: "کنشگران",
    scopes: "حوزه‌ها",
    eventGroups: "گروه‌های رویداد",
    eventSources: "منابع رویداد",
    events: "رویدادها",
    calendar: "تقویم جامع",
    "unApproved-events": "رویدادهای تایید نشده",
    reports: "گزارش‌ها"
  },
  weekDays: [
    {
      full: [
        "شنبه",
        "یکشنبه",
        "دوشنبه",
        "سه‌شنبه",
        "چهارشنبه",
        "پنجشنبه",
        "جمعه"
      ]
    },
    {
      full: [
        "یکشنبه",
        "دوشنبه",
        "سه‌شنبه",
        "چهارشنبه",
        "پنجشنبه",
        "جمعه",
        "شنبه"
      ]
    }
  ],
  dataTable: {
    ...fa.dataTable,
    refFieldMore: "برای دیدن موارد بیشتر حداقل ۲ حرف آن را وارد کنید",
    dontCare: "مهم نیست",
    removeSuccessfully: "حذف با موفقیت انجام شد"
  },
  roles: {
    client: "کاربر عادی",
    admin: "ادمین",
    operator: "اپراتور",
    all: "همه"
  },
  validation: {
    required: "پر کردن این فیلد الزامی است",
    mobileNumber: "شماره همراه را صحیح وارد کنید",
    nationalCode: "کد ملی را صحیح وارد کنید",
    justNumber: "تنها عدد مجاز است",
    numberRange: "عدد در بازه مجاز نیست",
    wrong: "مقدار وارد شده صحیح نیست"
  },
  pages: {
    login: {
      login: "ورود",
      mainText:
        "برای ورود به سامانه تقویم لطفا شماره موبایل خود را وارد نمایید تا کد ورود به سیستم برای شما ارسال گردد",
      mobileNumber: "شماره همراه",
      send: "ارسال",
      confirmMobileNumber: "تایید شماره همراه",
      editNumber: "تغییر شماره همراه",
      verificationCode: "کد تایید"
    },
    users: {
      status: "وضعیت فعالیت",
      firstName: "نام",
      lastName: "نام خانوادگی",
      role: "نقش",
      mobileNo: "شماره همراه",
      createAt: "تاریخ عضویت",
      lastUpdate: "آخرین تغییرات",
      lastLogin: "آخرین ورود",
      organization: "سازمان",
      actions: "عملیات",
      eventGroups: "گروه‌های رویداد",
      headers: {
        add: "افزودن کاربر",
        edit: "ویرایش کاربر"
      }
    },
    actors: {
      name: "نام",
      description: "توضیحات",
      createAt: "تاریخ افزوده شدن",
      lastUpdate: "آخرین تغییرات",
      status: "وضعیت فعالیت",
      actions: "عملیات",
      headers: {
        add: "افزودن کنشگر",
        edit: "ویرایش کنشگر"
      },
      eventGroups: "گروه‌های رویداد"
    },
    scopes: {
      name: "نام",
      description: "توضیحات",
      createAt: "تاریخ افزوده شدن",
      lastUpdate: "آخرین تغییرات",
      status: "وضعیت فعالیت",
      actions: "عملیات",
      headers: {
        add: "افزودن حوزه",
        edit: "ویرایش حوزه"
      },
      eventGroups: "گروه‌های رویداد"
    },
    eventGroups: {
      name: "نام",
      description: "توضیحات",
      createAt: "تاریخ افزوده شدن",
      lastUpdate: "آخرین تغییرات",
      status: "وضعیت فعالیت",
      actions: "عملیات",
      users: "کاربران",
      actors: "کنشگران",
      scopes: "حوزه‌ها",
      headers: {
        add: "افزودن گروه رویداد",
        edit: "ویرایش گروه رویداد"
      }
    },
    eventSources: {
      title: "عنوان",
      description: "توضیحات",
      createAt: "تاریخ افزوده شدن",
      lastUpdate: "آخرین تغییرات",
      eventGroup: "گروه رویداد",
      duration: "طول رویداد (روز)",
      status: "وضعیت فعالیت",
      actions: "عملیات",
      users: "کاربران",
      actors: "کنشگران",
      scopes: "حوزه‌ها",
      end: "پایان",
      start: "شروع",
      color: "رنگ",
      calendarType: "نوع تقوم",
      repetitiveType: "نوع تکرار",
      repeatPeriod: "بازه تکرار",
      area: "گستره",
      isMainSubject: "موضوع اصلی",
      score: "وزن رویداد",
      viewEvents: "مشاهده رویدادهای مرتبط",
      headers: {
        add: "افزودن گروه رویداد",
        edit: "ویرایش گروه رویداد"
      }
    },
    events: {
      title: "عنوان",
      description: "توضیحات",
      createAt: "تاریخ افزوده شدن",
      lastUpdate: "آخرین تغییرات",
      eventGroup: "گروه رویداد",
      duration: "طول رویداد (روز)",
      status: "وضعیت فعالیت",
      actions: "عملیات",
      users: "کاربران",
      actors: "کنشگران",
      scopes: "حوزه‌ها",
      end: "پایان",
      start: "شروع",
      color: "رنگ",
      eventSource: "منبع رویداد",
      calendarType: "نوع تقوم",
      repetitiveType: "نوع تکرار",
      repeatPeriod: "بازه تکرار",
      area: "گستره",
      isMainSubject: "موضوع اصلی",
      score: "وزن رویداد",
      viewEvents: "مشاهده رویدادهای مرتبط",
      headers: {
        add: "افزودن گروه رویداد",
        edit: "ویرایش گروه رویداد"
      }
    },
    calendar: {
      gotoDate: "رفتن به تاریخ",
      filters: "فیلترها",
      addEvent: "افزودن رویداد",
      editEvent: "ویرایش رویداد",
      moreEvent: "{0} رویداد دیگر",
      eventGroup: "گروه رویداد",
      actors: "کنشگران",
      scopes: "حوزه‌ها",
      cancel: "لغو افزودن",
      editRepeativeEvent: "ویرایش رویداد تکرارشونده",
      deleteRepeativeEvent: "حذف رویداد تکرارشونده",
      deleteNonRepeativeEvent: "آیا از حذف این رویداد مطمئن هستید؟",
      approve: "پذیرفتن",
      reject: "رد کردن",
      deleteBulkMessage: "آیا از حذف رویدادهای انتخابی اطمینان دارید؟",
      reSelect: "انتخاب مجدد",
      calendarType: {
        Jalaali: "هجری شمسی",
        Gregorian: "میلادی",
        Hijri: "هجری قمری",
        undefined: "",
        all: "همه"
      },
      repetitiveType: {
        none: "بدون تکرار",
        daily: "روزانه",
        weekly: "هفتگی",
        monthly: "ماهانه",
        annual: "سالانه",
        undefined: "",
        all: "همه"
      },
      area: {
        local: "محلی",
        region: "منطقه‌ای",
        province: "استانی",
        country: "کشوری",
        global: "جهانی",
        undefined: "",
        all: "همه"
      },
      deleteTypes: {
        this: "این رویداد",
        all: "همه رویدادهای وابسته",
        fromNow: "همه رویدادهای وابسته از امروز به بعد",
        thisAndFollow: "این رویداد و رویدادهای بعدی وابسته"
      },
      events: {
        title: "عنوان",
        start: "شروع",
        end: "پایان تکرار رویداد",
        description: "توضیحات",
        eventGroup: "گروه رویدادی",
        duration: "طول رویداد (روز)",
        calendarType: "نوع تقوم",
        color: "رنگ",
        repetitiveType: "نوع تکرار",
        repeatPeriod: "بازه تکرار",
        area: "گستره",
        isMainSubject: "موضوع اصلی",
        actors: "کنشگران",
        scopes: "حوزه‌ها",
        score: "وزن رویداد",
        actions: "عملیات"
      }
    },
    reports: {
      range: "بازه زمانی"
    }
  }
};
