import router from "@/router";
import { authenticationService } from "@/services/auth";

// ##################################################################################
// ################################### State ########################################
// ##################################################################################
export const state = {
  accessToken: undefined,
  sendCodeMessage: "",
  requests: {
    codeRequest: false,
    login: false
  },
  mobile: "",
  user: {},
  referer: undefined,
  errors: {}
};

// ##################################################################################
// ################################## Getters #######################################
// ##################################################################################
export const getters = {
  getAccessToken: state => state.accessToken,
  getRequest: state => name => {
    return !!state.requests[name];
  },
  getErrors: state => name => {
    return !!state.errors[name];
  },
  hasMobile: state => !!state.mobile,
  getSendCodeMessage: state => state.sendCodeMessage,
  getUserRole: state => state.user && state.user.role,
  isAdmin: state => state.user && state.user.role === "admin",
  getUser: state => state.user,
  getReferer: state => state.referer
};

// ##################################################################################
// ################################## Mutations #####################################
// ##################################################################################
export const mutations = {
  updateAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },
  setSendCodeMessage(state, sendCodeMessage) {
    state.sendCodeMessage = sendCodeMessage;
  },
  updateMobile(state, mobile) {
    state.mobile = mobile;
  },
  updateRequest(state, { name, value }) {
    state.requests[name] = value;
  },
  updateErrors(state, { name, value }) {
    state.errors[name] = value;
  },
  loginSuccess(state, result) {
    state.accessToken = result.token;
    localStorage.setItem("refresh_token", result.refreshToken);
  },
  setUser(state, user) {
    state.user = { ...user };
  },
  setReferer(state, referer) {
    state.referer = referer;
  }
};

// ##################################################################################
// ################################### Actions ######################################
// ##################################################################################
export const actions = {
  codeRequest({ commit }, mobile) {
    commit("updateRequest", {
      name: "codeRequest",
      value: true
    });
    authenticationService
      .codeRequest(mobile)
      .then(
        res => {
          commit("updateMobile", mobile);
          commit("setSendCodeMessage", res.data.msgFa);
        },
        error => {
          console.log(error);
        }
      )
      .finally(() => {
        commit("updateRequest", {
          name: "codeRequest",
          value: false
        });
      });
  },
  login({ commit, state }, { code, cb }) {
    commit("updateRequest", {
      name: "login",
      value: true
    });
    authenticationService
      .login(state.mobile, code)
      .then(
        res => {
          commit("loginSuccess", res.data);
          commit("setUser", res.data._user);
          cb && cb(res);
        },
        () => cb && cb()
      )
      .finally(() => {
        commit("updateRequest", {
          name: "login",
          value: false
        });
      });
  },
  loginWithToken({ commit }) {
    return new Promise(resolve => {
      authenticationService
        .loginWithToken()
        .then(
          res => {
            commit("setUser", res.data.user);
            commit("updateAccessToken", res.data.token);
            resolve(res.data);
          },
          () => {
            router.replace({
              name: "login"
            });
          }
        )
        .finally(() => {
          commit("updateRequest", {
            name: "login",
            value: false
          });
        });
    });
  },
  logout({ commit }) {
    authenticationService.logOut().then(() => {
      commit("updateAccessToken", undefined);
      commit("setUser", {});
      commit("updateMobile", "");
      localStorage.removeItem("refresh_token");
      router.replace({ name: "login" });
    });
  }
};
export const namespaced = true;
