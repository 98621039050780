import clamp from "clamp-js";
export default {
  name: "clamp",
  props: {
    tag: {
      type: String,
      default: "div"
    },
    maxLines: Number,
    maxHeight: [String, Number],
    ellipsis: {
      type: String,
      default: "…"
    }
  },
  render(h) {
    return h(
      this.tag,
      {
        ref: "clamp"
      },
      this.$slots.default
    );
  },
  methods: {
    clamping() {
      clamp(this.$refs.clamp, {
        clamp: this.maxLines || this.maxHeight,
        useNativeClamp: true,
        truncationChar: this.ellipsis
      });
    }
  },
  mounted() {
    this.clamping();
  }
};
