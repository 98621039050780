import "babel-polyfill";
import "idempotent-babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import fragment from "./plugins/fragment";
import globalRules from "./plugins/rules";
import numeral from "./plugins/numeral";
import myFilters from "./plugins/filters";
import "vazir-font/dist/font-face.css";
// import "vazir-font/dist/Farsi-Digits/font-face-FD.css";
import "vue2-animate/dist/vue2-animate.min.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = process.env.NODE_ENV === "production";
Vue.config.performance = true;

new Vue({
  router,
  store,
  vuetify,
  fragment,
  numeral,
  myFilters,
  globalRules,
  render: h => h(App)
}).$mount("#app");
