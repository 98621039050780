// ##################################################################################
// ################################### State ########################################
// ##################################################################################
export const state = {
  snackbarItems: [],
  calendarType: undefined,
  inputDate: new Date()
};
const calendarTypes = ["Jalaali", "Gregorian"];
// ##################################################################################
// ################################## Getters #######################################
// ##################################################################################
export const getters = {
  //get snakbar item
  getSnackBarItem(state) {
    return state.snackbarItems[0] || {};
  },
  getCalendarType(state) {
    return calendarTypes.indexOf(
      state.calendarType || localStorage.getItem("calendarType") || "Jalaali"
    );
  },
  getInputDate(state) {
    return state.inputDate;
  }
};

// ##################################################################################
// ################################## Mutations #####################################
// ##################################################################################
export const mutations = {
  // ###################### set snackbar items #############################
  SET_SNACKBAR_ITEMS(state, payload) {
    state.snackbarItems = [...state.snackbarItems, payload];
  },

  CLOSE_SNAKBAR(state) {
    state.snackbarItems.shift();
  },

  setCalendarType(state, calendarType) {
    const _calendarType = calendarTypes[calendarType];
    localStorage.setItem("calendarType", _calendarType);
    state.calendarType = _calendarType;
  },

  setInputDate(state, inputDate) {
    state.inputDate = inputDate;
  }
};

// ##################################################################################
// ################################### Actions ######################################
// ##################################################################################
export const actions = {
  // ######################## show snackbar #########################################
  showSnackbar({ commit }, payload) {
    commit("SET_SNACKBAR_ITEMS", payload);
  }
};

export const namespaced = true;
