import Vue from "vue";
import Vuetify from "vuetify/lib";
import fa from "@/i18n/fa";
import VuePersianDateTimePicker from "vue-persian-datetime-picker";
import clamp from "@/components/Clamp";
Vue.component("date-picker", VuePersianDateTimePicker);
Vue.component("v-clamp", clamp);
Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#2196F3",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00"
      },
      dark: {
        primary: "#2196F3",
        secondary: "#424242",
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00"
      }
    }
  },
  lang: {
    locales: { fa },
    current: "fa"
  }
});
