<template>
  <transition
    :enter-active-class="
      $vuetify.breakpoint.mobile ? 'animated fadeInDown' : 'animated fadeInUp'
    "
    :leave-active-class="
      $vuetify.breakpoint.mobile ? 'animated fadeOutUp' : 'animated fadeOutDown'
    "
    mode="out-in"
  >
    <v-snackbar
      :key="new Date().getTime()"
      v-model="snackbar"
      v-if="snackbar"
      :bottom="!$vuetify.breakpoint.mobile"
      :top="$vuetify.breakpoint.mobile"
      :color="snackbarItems.color"
      :multi-line="$vuetify.breakpoint.xsOnly && snackbarItems.text.length > 45"
      :timeout="-1"
    >
      <v-icon dark class="mx-4">{{
        snackbarItems.color === "success"
          ? "mdi-check-circle"
          : snackbarItems.color === "info"
          ? "mdi-alert-circle-outline"
          : "mdi-alert-outline"
      }}</v-icon>
      {{ snackbarItems.text }}

      <template #action="{ attrs }">
        <v-btn
          :icon="!snackbarItems.linkText"
          :to="snackbarItems.linkTo"
          v-bind="attrs"
          @click="btnClick"
        >
          <v-icon v-if="!snackbarItems.linkText" class="mx-2">{{
            snackbarItems.icon || "mdi-close"
          }}</v-icon>
          <span v-else>{{ snackbarItems.linkText }}</span>
        </v-btn>
      </template>
    </v-snackbar>
  </transition>
</template>

<script>
let timer;
export default {
  data() {
    return {
      snackbar: false
    };
  },

  watch: {
    snackbarItems: {
      handler: function(val) {
        if (!Reflect.ownKeys(val).length) return (this.snackbar = false);
        this.showSnackBar(val);
      },
      deep: true
    }
  },

  computed: {
    snackbarItems() {
      return this.$store.getters["global/getSnackBarItem"];
    }
  },
  methods: {
    btnClick() {
      this.snackbarItems.btnClick && this.snackbarItems.btnClick();
      this.closeSnackBar();
    },
    closeSnackBar() {
      this.snackbar = false;
      this.$store.commit("global/CLOSE_SNAKBAR");
      clearTimeout(timer);
    },
    showSnackBar(val) {
      this.snackbar = true;
      const timeout = val.timeout || 5000;
      if (timeout > 0) {
        timer = setTimeout(() => {
          this.closeSnackBar();
        }, timeout);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
