<template>
  <v-app>
    <router-view :key="$route.path"></router-view>
    <Snakbar></Snakbar>
  </v-app>
</template>

<script>
import Snakbar from "@/components/Snakbar.vue";
export default {
  name: "App",
  components: {
    Snakbar
  }
};
</script>

<style lang="scss">
html {
  overflow: auto;
  font-family: vazir;
}
body {
  overflow-x: hidden;
  -webkit-print-color-adjust: exact !important;
}
.v-slide-group__wrapper::after {
  @media print {
    display: none !important;
  }
}
.wordCloud text {
  cursor: pointer;
}
.none-pointer-envent {
  pointer-events: none;
}
.v-slide-group__wrapper::after {
  content: "";
  display: block;
  width: 20px;
  height: 100%;
  background: linear-gradient(to right, white, transparent);
  position: absolute;
  left: 0;
  z-index: 10;
}
.multiline-text-truncate {
  &::before {
    position: absolute;
    content: "...";
    bottom: 0;
    right: 0;
  }
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem;
  &::after {
    content: "";
    position: absolute;
    /* inset-inline-end: 0; */
    right: 0;
    width: 1rem;
    height: 1rem;
    background: white;
  }
}
.animation-speed {
  @for $i from 1 through 5 {
    &-#{$i} {
      animation-duration: #{(6-$i) * 150}ms !important;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.no-delay {
  animation-delay: 0;
}
.v-btn__content {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.relative-position {
  position: relative;
}

::-webkit-scrollbar {
  z-index: 100;
  width: 8px;
  height: 8px !important;
}
::-webkit-scrollbar:hover {
  width: 10px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(150, 150, 150, 0.3);
  border-radius: 8px !important;
}
::-webkit-scrollbar-track {
  background: none;
  border-radius: 8px !important;
}
</style>
