import Vue from "vue";
import numeral from "numeral";
import "numeral/locales";

const abbreviate = function abbreviate(value) {
  return numeral(value).format("0.0a");
};

const bytes = function bytes(value) {
  return numeral(value).format("0 b");
};

const exposedNumeral = function exposedNumeral(value, format) {
  return numeral(value).format(format);
};

const exponential = function exponential(value) {
  return numeral(value).format("0.[00]e+0");
};

const ordinal = function ordinal(value) {
  return numeral(value).format("Oo");
};

const percentage = function percentage(value) {
  return numeral(value).format("0.[00]%");
};

const separator = function separator(value) {
  return numeral(value).format("0,0");
};

const currency = function currency(value) {
  return numeral(value).format("0,0‌‌ $");
};

const vueNumeralFilterInstaller = {
  install: function install(vue) {
    numeral.register("locale", "fa", {
      delimiters: {
        thousands: "٬",
        decimal: "٫"
      },
      abbreviations: {
        thousand: "هزار",
        million: "میلیون",
        billion: "میلیارد",
        trillion: "بیلیون"
      },
      ordinal: function(number) {
        const b = number % 10;
        return ~~((number % 100) / 10) === 1
          ? "th"
          : b === 1
          ? "st"
          : b === 2
          ? "nd"
          : b === 3
          ? "rd"
          : "th";
      },
      currency: {
        symbol: "تومان"
      }
    });
    numeral.locale("fa");
    vue.filter("abbreviate", abbreviate);
    vue.filter("bytes", bytes);
    vue.filter("exponential", exponential);
    vue.filter("numeral", exposedNumeral);
    vue.filter("ordinal", ordinal);
    vue.filter("percentage", percentage);
    vue.filter("separator", separator);
    vue.filter("currency", currency);
  }
};

Vue.use(vueNumeralFilterInstaller);

export default Vue;
