/* eslint-disable prettier/prettier */
import store from "@/store";
import router from "@/router";
import axios from "axios";
import {
  authenticationService
} from "./auth";

// ##########################################################################################
// #######################  create a new instance of axios  #################################
// ##########################################################################################
let api = axios.create({
  baseURL: process.env.VUE_APP_API_URL || "https://api.iems.fudev.ir/v1",
  headers: {
    "Content-Type": "application/json"
  }
});

// ##########################################################################################
// ######################  intercept requests before they are handled  ######################
// ##########################################################################################
api.interceptors.request.use(request => {
  // if accessToken existed add authorization header with jwt token to each request
  const accessToken = store.getters["auth/getAccessToken"];
  if (accessToken) {
    request.headers.common["Authorization"] = accessToken;
  }
  return request;
});

// ##########################################################################################
// ######################  intercept responses before they are handled  ######################
// ##########################################################################################

// here, refresh token must be handled
api.interceptors.response.use(
  response => {
    if (response.data.msgFa || response.data.msgEn) {
      store.dispatch('global/showSnackbar', { text: response.data.msgFa || response.data.msgEn, color: "success" });
    }
    return response;
  },
  error => {
    const originalRequest = error.config;
    const tokenIsExpire = error.response.status === 401 || error.response.status === 403;
    if(tokenIsExpire && originalRequest.__isRefreshToken){
      store.state.auth.user = null;
      store.commit("auth/setAccessToken", null);
      localStorage.removeItem("refresh_token");
      router.replace("/");
    } else if (tokenIsExpire && !originalRequest.__isRetryRequest) {
      originalRequest.__isRetryRequest = true;
      return authenticationService
        .loginWithToken()
        .then(res => {
          store.commit("auth/setAccessToken", res.data.token);
          originalRequest.headers["Authorization"] = res.data.token;
          return api(originalRequest);
        })
        .catch(err => {
          if (err.response.status == 404) {
            store.state.auth.user = null;
            store.commit("auth/setAccessToken", null);
            localStorage.removeItem("refresh_token");
            router.replace("/");
          }
        });
    } else {
      if (error.response.status === 433 || error.response.status === 478) {
        store.dispatch('global/showSnackbar', { color: 'error', text: error.response.data.msgFa, timeout: -1,linkText: "تغییر سبد خرید", linkTo: {name: "select-products"} });
      } else {
        store.dispatch('global/showSnackbar', { color: 'error', text: error.response.data.msgFa  || error.response.data.msgEn });
      }
      return Promise.reject(error.response,error.code);
    }
  }
);

export default api;
