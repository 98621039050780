import api from "./api";

export const authenticationService = {
  codeRequest: async mobile => api.post("/auth/codeRequest", { mobile }),
  login: async (mobileNo, loginCode) =>
    api.post("/auth/login", { mobileNo, loginCode }),
  loginWithToken: async () => {
    const token = localStorage.getItem("refresh_token");
    if (token) {
      return api.post("/auth/loginWithToken", undefined, {
        headers: {
          Authorization: token
        },
        __isRefreshToken: true
      });
    } else {
      return Promise.reject();
    }
  },
  logOut: async () =>
    api.post("/auth/logOut", undefined, {
      headers: {
        Authorization: localStorage.getItem("refresh_token")
      }
    })
};
