import Vue from "vue";
import jMoment from "moment-jalaali";
import { format as dateFormat } from "date-fns-jalali";
jMoment.loadPersian({ dialect: "persian-modern", usePersianDigits: true });

const moment = function moment(value, format) {
  return value ? jMoment(value).format(format) : "-";
};

const dateFnsFormat = function dateFnsFormat(value, format, options) {
  return value ? dateFormat(value, format, options) : "";
};

export const persianNumber = string =>
  string === undefined
    ? ""
    : String(string)
        .replace(/0/g, "۰")
        .replace(/1/g, "۱")
        .replace(/2/g, "۲")
        .replace(/3/g, "۳")
        .replace(/4/g, "۴")
        .replace(/5/g, "۵")
        .replace(/6/g, "۶")
        .replace(/7/g, "۷")
        .replace(/8/g, "۸")
        .replace(/9/g, "۹")
        .replace(/٠/g, "۰")
        .replace(/١/g, "۱")
        .replace(/٢/g, "۲")
        .replace(/٣/g, "۳")
        .replace(/٤/g, "۴")
        .replace(/٥/g, "۵")
        .replace(/٦/g, "۶")
        .replace(/٧/g, "۷")
        .replace(/٨/g, "۸")
        .replace(/٩/g, "۹");

const filters = {
  install: function install(vue) {
    vue.filter("persianNumber", persianNumber);
    vue.filter("toToman", value => value / 10);
    vue.filter("jMoment", moment);
    vue.filter("dateFormat", dateFnsFormat);
  }
};

Vue.use(filters);

export default Vue;
